<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤报表 / <span>个人考勤明细</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_t_left">
          <el-date-picker
            v-model="date"
            type="daterange"
            @change="changeDate"
            range-separator="-"
            class="department_search_date"
            start-placeholder="开始日期"
            :picker-options="expireTimeOPtion"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-select
            ref="stlectTree"
            v-model="selectTree"
            @change="changeDe"
            clearable
            placeholder="部门"
            :popper-append-to-body="false"
            class="select-tree"
          >
            <el-option :value="selectTreeValue" style="height: auto">
              <!-- option展开高度太小，把height设置为auto就好啦 -->
              <el-tree
                :data="departmentList"
                node-key="departmentId"
                ref="tree"
                accordion
                highlight-current
                :props="defaultProps"
                @node-click="nodeClick"
              ></el-tree>
            </el-option>
          </el-select>
          <div class="visitor_information_search">
            <el-input
              class="department_search_inp"
              v-model="searchValue"
              placeholder="搜索员工工号、姓名"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearup"
              v-if="searchicon"
            />
          </div>
          <el-button
            class="visitor_information_bto"
            type="primary"
            @click="searchSetof(1)"
            >查询</el-button
          >
          <el-button class="visitor_information_bto2" @click="reset"
            >重置</el-button
          >
        </div>
        <div>
          <el-button @click="setReport">设置报表</el-button>
          <el-button @click="startDownload">导出报表</el-button>
        </div>
      </div>
      <div class="visitor_information_table">
        <el-table
          v-loading="tableloading"
          height="95%"
          ref="multipleTable"
          :data="tableData"
          :key="tableKey"
          border
          class="v_table"
          row-key="id"
          @selection-change="handleSelectionChange"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column label="姓名" width="200" fixed>
            <template slot-scope="scope">
              <div v-if="scope.row.umsUser">{{ scope.row.umsUser.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="员工工号" width="150" fixed>
            <template slot-scope="scope">
              <div v-if="scope.row.umsUser">
                {{ scope.row.umsUser.jobNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="部门" width="150" fixed>
            <template slot-scope="scope">
              <div v-if="scope.row.umsDepartment">
                {{ scope.row.umsDepartment.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="dayString"
            label="日期"
            min-width="200"
            v-if="judgingHeader(4)"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.dayString }}({{ weekDay(scope.row.dayString) }})
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="考勤组名称"
            min-width="200"
            v-if="judgingHeader(5)"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.bmsAttendanceGroupGetVO">
                <el-tooltip
                  class="item"
                  v-if="!isEmptyObject(scope.row.bmsAttendanceGroupGetVO)"
                  effect="dark"
                  :content="scope.row.bmsAttendanceGroupGetVO.name"
                  placement="top"
                >
                  <el-link
                    type="primary"
                    @click="checkRule(scope.row.configGroup)"
                    >{{ scope.row.bmsAttendanceGroupGetVO.name }}</el-link
                  >
                </el-tooltip>
                <span v-else>- -</span>
              </span>
              <span v-else>- -</span>
            </template>
          </el-table-column>
          <el-table-column
            label="班次考勤时间"
            min-width="180"
            v-if="judgingHeader(6)"
          >
            <template slot-scope="scope">
              <div>
                <div
                  v-if="
                    scope.row.bmsAttendanceArrangeGetVO && scope.row.arrange > 0
                  "
                >
                  <div
                    v-if="!isEmptyObject(scope.row.bmsAttendanceArrangeGetVO)"
                  >
                    <el-link
                      @click="checkClasses(scope.row.configArrange)"
                      type="primary"
                      v-for="(item, index) in handleclasses(
                        scope.row.bmsAttendanceArrangeGetVO,
                        2
                      )"
                      :key="index"
                      >{{ item }}</el-link
                    >
                  </div>
                  <div v-else>- -</div>
                </div>
                <div v-else>{{ handleclasses2(scope.row) }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="打卡时间"
            min-width="200"
            v-if="judgingHeader(7)"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.action && scope.row.arrange > 0">
                <div
                  v-for="(item, index) in replaceSymbol(
                    scope.row.action,
                    false,
                    scope.row.bmsAttendanceArrangeGetVO
                  )"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <div v-else-if="scope.row.action && scope.row.arrange == -4">
                <div>
                  {{
                    replaceSymbol2(
                      scope.row.action,
                      scope.row.bmsAttendanceGroupGetVO
                    )
                  }}
                </div>
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column
            label="标准工作时长"
            min-width="200"
            v-if="judgingHeader(9)"
          >
            <template slot-scope="scope">
              <div style="color: #0058ff">
                <div>
                  {{ calculateHours(scope.row) }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="实际工作时长"
            min-width="200"
            v-if="judgingHeader(10)"
          >
            <template slot-scope="scope">
              <div style="color: #0058ff">
                <div v-if="scope.row.secWork > 0">
                  {{ calculateActual(scope.row.secWork) }}小时
                </div>
                <div v-else>- -</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="假勤申请"
            min-width="300"
            v-if="judgingHeader(11)"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.bmsApplyList">
                <el-popover
                  v-if="scope.row.bmsApplyList"
                  placement="bottom"
                  trigger="hover"
                  :key="Math.random()"
                >
                  <div>
                    <div>假勤申请详情</div>
                    <div class="leaveDetails">
                      <el-link
                        @click="
                          checkAttendance(scope.row.bmsApplyList[index].id)
                        "
                        type="primary"
                        :key="index"
                        v-for="(item, index) in scope.row.bmsApplyListArr"
                        >{{ item }}</el-link
                      >
                    </div>
                  </div>
                  <div slot="reference" class="fakeAttendance">
                    {{ hadleAttendance(scope.row.bmsApplyList, 2) }}
                  </div>
                </el-popover>
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column
            label="异常统计"
            v-if="judgingHeader2([13, 14, 15, 16, 17])"
          >
            <el-table-column
              label="迟到次数"
              min-width="100"
              v-if="judgingHeader(13)"
            >
              <template slot-scope="scope">
                <div class="warning" v-if="scope.row.lateCount != 0">
                  {{ scope.row.lateCount }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="迟到时长(分钟)"
              min-width="150"
              v-if="judgingHeader(14)"
            >
              <template slot-scope="scope">
                <div
                  class="warning"
                  v-if="minuteConversion(scope.row.secLate, 1) != 0"
                >
                  {{ minuteConversion(scope.row.secLate, 1) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="早退次数"
              min-width="100"
              v-if="judgingHeader(15)"
            >
              <template slot-scope="scope">
                <div class="warning" v-if="scope.row.earlyCount != 0">
                  {{ scope.row.earlyCount }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="早退时长(分钟)"
              min-width="150"
              v-if="judgingHeader(16)"
            >
              <template slot-scope="scope">
                <div
                  class="warning"
                  v-if="minuteConversion(scope.row.secEarly, 2) != 0"
                >
                  {{ minuteConversion(scope.row.secEarly, 2) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="旷工天数"
              min-width="100"
              v-if="judgingHeader(17)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayAbsence == 0">- -</div>
                <div v-else class="warning">{{ scope.row.dayAbsence }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="假勤情况"
            v-if="judgingHeader2([18, 19, 21, 22, 23, 24, 25, 26, 27, 28, 29])"
          >
            <el-table-column
              label="补卡次数"
              min-width="120"
              v-if="judgingHeader(19)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.addCount > 0">
                  {{ scope.row.addCount }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="外出"
              min-width="120"
              v-if="judgingHeader(21)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.secOut > 0">
                  {{ convertSecondsToHours(scope.row.secOut) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="出差"
              min-width="120"
              v-if="judgingHeader(22)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayBusiness > 0">
                  {{ scope.row.dayBusiness }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="年假"
              min-width="120"
              v-if="judgingHeader(23)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestYear > 0">
                  {{ scope.row.dayRestYear }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="事假"
              min-width="120"
              v-if="judgingHeader(24)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestMatter > 0">
                  {{ scope.row.dayRestMatter }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="病假"
              min-width="120"
              v-if="judgingHeader(25)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestSick > 0">
                  {{ scope.row.dayRestSick }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="调休假"
              min-width="120"
              v-if="judgingHeader(26)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestExchange > 0">
                  {{ scope.row.dayRestExchange }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="婚假"
              min-width="120"
              v-if="judgingHeader(27)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestMarry > 0">
                  {{ scope.row.dayRestMarry }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="产休假"
              min-width="120"
              v-if="judgingHeader(28)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestMaternity > 0">
                  {{ scope.row.dayRestMaternity }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="其他"
              min-width="120"
              v-if="judgingHeader(29)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.dayRestOther > 0">
                  {{ scope.row.dayRestOther }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="加班统计"
            v-if="judgingHeader2([30, 31, 32, 33, 34])"
          >
            <el-table-column
              label="加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(31)"
            >
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.secOvertime != 0"
                  placement="top-start"
                  title="最早最晚打卡时间"
                  width="200"
                  trigger="hover"
                >
                  <div>{{ checkOverwork(scope.row.actionOvertime) }}</div>
                  <div slot="reference">
                    {{ handleOvertime(scope.row.secOvertime) }}
                  </div>
                </el-popover>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="工作日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(32)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.work && scope.row.work != 0">
                  {{ handleOvertime(scope.row.work) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="休息日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(33)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.rest && scope.row.rest != 0">
                  {{ handleOvertime(scope.row.rest) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="节假日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(34)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.holiday && scope.row.holiday != 0">
                  {{ handleOvertime(scope.row.holiday) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="newpagination"
        v-if="pages.total > 0"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 设置报表 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :visible.sync="setReportDialog"
      :with-header="false"
      size="25%"
    >
      <div class="batch_t">
        <div class="branch_l">
          <img
            src="@/assets/structure/close.png"
            class="batch_close"
            @click="close"
          />
          <div>设置报表</div>
        </div>
        <div class="branch_bto">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="switcOperation">确认</el-button>
        </div>
      </div>
      <div class="batch_c">
        <el-tree
          :data="setReportList"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultProps2"
        >
        </el-tree>
      </div>
    </el-drawer>
    <!-- 查看班次 -->
    <el-dialog
      title="当日班次考勤时间"
      @close="cancelDiglog"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="70%"
      :key="key"
      class="classdiglog"
    >
      <AddClasses :isCheck="true" @cancel="cancelDiglog" :id="currentClassid" />
    </el-dialog>
    <!-- 考勤详情 -->
    <el-dialog
      :title="title"
      @close="closeAttendanceDialog"
      :visible.sync="attendanceDialog"
      :close-on-click-modal="false"
      width="50%"
      :key="fakeKey"
      class="classdiglog"
    >
      <div class="fake">
        <div class="fake_top">
          <div class="fake_top_title" v-if="attendanceObject.umsUser">
            {{ attendanceObject.umsUser.name }}的
          </div>
          <div class="fake_top_title" v-if="attendanceObject.type == 0">
            加班
          </div>
          <div class="fake_top_title" v-if="attendanceObject.type == 1">
            补卡
          </div>
          <div class="fake_top_title" v-if="attendanceObject.type == 2">
            请假
          </div>
          <div class="fake_top_title" v-if="attendanceObject.type == 3">
            出差
          </div>
          <div class="fake_top_title" v-if="attendanceObject.type == 4">
            外出
          </div>
          <div v-if="attendanceObject.status == 0">申请中</div>
          <div v-if="attendanceObject.status == 1" class="success">已通过</div>
          <div v-if="attendanceObject.status == 2" class="error">已驳回</div>
        </div>
        <div>
          <div class="fake_content">
            <div class="fake_content_title">提交时间</div>
            <div>{{ attendanceObject.createTime }}</div>
          </div>
          <div class="fake_content">
            <div class="fake_content_title">所在部门</div>
            <div v-if="attendanceObject.umsDepartment">
              {{ attendanceObject.umsDepartment.name }}
            </div>
          </div>
          <div v-if="attendanceObject.type == 1">
            <div class="fake_content">
              <div class="fake_content_title">补卡日期</div>
              <div>{{ splitStr(attendanceObject.timeRecord, 1) }}</div>
            </div>
            <div class="fake_content">
              <div class="fake_content_title">补卡时间</div>
              <div>{{ splitStr(attendanceObject.timeRecord, 2) }}</div>
            </div>
            <div class="fake_content">
              <div class="fake_content_title">补卡类型</div>
              <div v-if="attendanceObject.typeRecord == 0">旷工</div>
              <div v-if="attendanceObject.typeRecord == 1">迟到</div>
              <div v-if="attendanceObject.typeRecord == 2">早退</div>
              <div v-if="attendanceObject.typeRecord == 3">其他</div>
            </div>
          </div>
          <div v-else>
            <div class="fake_content">
              <div class="fake_content_title" v-if="attendanceObject.type == 0">
                加班开始时间
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 2">
                请假开始时间
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 3">
                出差开始时间
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 4">
                外出开始时间
              </div>
              <div
                v-if="attendanceObject.type == 0 || attendanceObject.type == 4"
              >
                {{ attendanceObject.timeBegin }}
              </div>
              <div v-else>
                {{ leaveTime(attendanceObject.description, 1)
                }}{{ attendanceObject.timeBegin }}
              </div>
            </div>
            <div class="fake_content">
              <div class="fake_content_title" v-if="attendanceObject.type == 0">
                加班结束时间
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 2">
                请假结束时间
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 3">
                出差结束时间
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 4">
                外出结束时间
              </div>
              <div
                v-if="attendanceObject.type == 0 || attendanceObject.type == 4"
              >
                {{ attendanceObject.timeEnd }}
              </div>
              <div v-else>
                {{ leaveTime(attendanceObject.description, 2)
                }}{{ attendanceObject.timeEnd }}
              </div>
            </div>
            <div class="fake_content">
              <div class="fake_content_title" v-if="attendanceObject.type == 0">
                加班时长
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 2">
                请假时长
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 3">
                出差时长
              </div>
              <div class="fake_content_title" v-if="attendanceObject.type == 4">
                外出时长
              </div>
              <div
                v-if="attendanceObject.type == 0 || attendanceObject.type == 4"
              >
                {{
                  calculateHourDifference(
                    attendanceObject.timeBegin,
                    attendanceObject.timeEnd
                  )
                }}
              </div>
              <div
                v-if="attendanceObject.type == 2 || attendanceObject.type == 3"
              >
                {{ formatHours(attendanceObject.description) }}
              </div>
            </div>
          </div>
          <div class="fake_content" v-if="attendanceObject.type == 2">
            <div class="fake_content_title">请假类型</div>
            <div v-if="attendanceObject.typeRest == 0">事假</div>
            <div v-if="attendanceObject.typeRest == 1">年假</div>
            <div v-if="attendanceObject.typeRest == 2">婚假</div>
            <div v-if="attendanceObject.typeRest == 3">产休假</div>
            <div v-if="attendanceObject.typeRest == 4">病假</div>
            <div v-if="attendanceObject.typeRest == 5">调休假</div>
            <div v-if="attendanceObject.typeRest == 6">其他</div>
          </div>
          <div class="fake_content">
            <div class="fake_content_title" v-if="attendanceObject.type == 0">
              加班事由
            </div>
            <div class="fake_content_title" v-if="attendanceObject.type == 1">
              补卡事由
            </div>
            <div class="fake_content_title" v-if="attendanceObject.type == 2">
              请假事由
            </div>
            <div class="fake_content_title" v-if="attendanceObject.type == 3">
              出差事由
            </div>
            <div class="fake_content_title" v-if="attendanceObject.type == 4">
              外出事由
            </div>
            <div
              v-if="attendanceObject.type == 2 || attendanceObject.type == 3"
            >
              {{ handleDesc(attendanceObject.description) }}
            </div>
            <div v-else>{{ attendanceObject.description }}</div>
          </div>
        </div>
        <div class="fake_process">
          <div class="fake_process_title">审批流程</div>
          <div class="fake_content">
            <div class="fake_content_title">审批人</div>
            <div v-if="attendanceObject.execUmsUser">
              {{ attendanceObject.execUmsUser.name }}
            </div>
          </div>
          <div class="fake_content">
            <div class="fake_content_title">审批状态</div>
            <div v-if="attendanceObject.status == 0">申请中</div>
            <div v-if="attendanceObject.status == 1">已通过</div>
            <div v-if="attendanceObject.status == 2">已驳回</div>
            <div v-if="attendanceObject.status == -1">已撤销</div>
            <div class="fake_content_time">{{ attendanceObject.timeExec }}</div>
          </div>
          <div class="fake_content" v-if="attendanceObject.opinion">
            <div class="fake_content_title">审批意见</div>
            <div>
              {{ attendanceObject.opinion }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 考勤规则 -->
    <el-dialog
      title="考勤规则"
      @close="cancelRuleDiglog"
      :visible.sync="dialogRules"
      :close-on-click-modal="false"
      width="70%"
      :key="ruleKey"
      class="classdiglog"
    >
      <Rule @cancel="cancelRuleDiglog" :group="currentAttendance" />
    </el-dialog>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
import AddClasses from "./dialog/classes.vue";
import Rule from "./dialog/rule.vue";
export default {
  data() {
    return {
      tableloading: true,
      searchValue: "", //搜索的关键词
      selectTree: "", //部门多选
      searchValueObjcet: {}, //搜索集合
      selectDeptid: [], //选择的部门id
      selectTreeValue: null, //部门多选
      departmentList: [], //部门列表
      title: "2342", //假勤详情标题
      attendanceDialog: false, //假勤弹窗
      attendanceObject: {}, //假勤信息
      fakeKey: Math.random(), //假勤弹窗key
      date: null, //日期
      startTime: "", //开始日期
      endTime: "", //结束日期
      searchicon: false, //监听输入
      tableData: [], //表格数据
      tipstitle: "确认删除此访客?",
      selectUserid: [], //最终删除的数组
      holidayList: [], //节假日日期
      selectedData: [],
      pages: {}, //分页
      multipleSelection: [],
      tableSelection: [], //导出表格需要
      company: {}, //公司信息
      delectVistorDiglog: false, //删除访客提示框
      tableKey: Math.random(), //表格key
      // 查看班次
      dialogFormVisible: false, //班次弹窗
      dialogRules: false, //规则弹窗
      ruleKey: Math.random(), //规则key
      currentClassid: null, //班次表格
      currentAttendance: null, //考勤组表格
      key: Math.random(),
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      defaultProps2: {
        children: "children",
        label: "label",
      },
      setReportDialog: false, //设置报表
      tableColumnList: [], //生成的报表头
      setReportList: [
        {
          label: "姓名",
          id: 1,
          disabled: true,
        },
        {
          label: "员工工号",
          id: 2,
          disabled: true,
        },
        {
          label: "部门",
          id: 3,
          disabled: true,
        },
        {
          label: "日期",
          id: 4,
        },
        {
          label: "考勤组名称",
          id: 5,
        },
        {
          label: "班次考勤时间",
          id: 6,
        },
        {
          label: "打卡时间",
          id: 7,
        },
        {
          label: "标准工作时长",
          id: 9,
        },
        {
          label: "实际工作时长",
          id: 10,
        },
        {
          label: "假勤申请",
          id: 11,
        },
        {
          label: "异常统计",
          id: 12,
          children: [
            {
              label: "迟到次数",
              id: 13,
            },
            {
              label: "迟到时长",
              id: 14,
            },
            {
              label: "早退次数",
              id: 15,
            },
            {
              label: "早退时长",
              id: 16,
            },
            {
              label: "旷工天数",
              id: 17,
            },
          ],
        },
        {
          label: "假勤情况",
          id: 19,
          children: [
            {
              label: "补卡次数",
              id: 19,
            },
            {
              label: "外出",
              id: 21,
            },
            {
              label: "出差",
              id: 22,
            },
            {
              label: "年假",
              id: 23,
            },
            {
              label: "事假",
              id: 24,
            },
            {
              label: "病假",
              id: 25,
            },
            {
              label: "调休假",
              id: 26,
            },
            {
              label: "婚假",
              id: 27,
            },
            {
              label: "产休假",
              id: 28,
            },
            {
              label: "其他",
              id: 29,
            },
          ],
        },
        {
          label: "加班统计",
          id: 30,
          children: [
            {
              label: "加班时长",
              id: 31,
            },
            {
              label: "工作日加班时长",
              id: 32,
            },
            {
              label: "休息日加班时长",
              id: 33,
            },
            {
              label: "节假日加班时长",
              id: 34,
            },
          ],
        },
      ], //报表总名称
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "nameText",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumbernew",
          id: 2,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 3,
        },
        {
          title: "日期",
          dataIndex: "dayStringName",
          id: 4,
        },
        {
          title: "考勤组名称",
          dataIndex: "attendanceGroupName",
          id: 5,
        },
        {
          title: "班次考勤时间",
          dataIndex: "classestime",
          id: 6,
        },
        {
          title: "打卡时间",
          dataIndex: "start",
          id: 7,
        },
        {
          title: "标准工作时长",
          dataIndex: "standard",
          id: 9,
        },
        {
          title: "实际工作时长",
          dataIndex: "actual",
          id: 10,
        },
        {
          title: "假勤申请",
          dataIndex: "fakeattendance",
          id: 11,
        },
        {
          title: "异常统计",
          children: [
            {
              title: "迟到次数",
              dataIndex: "lateCount",
              id: 13,
            },
            {
              title: "迟到时长(分钟)",
              dataIndex: "latetime",
              id: 14,
            },
            {
              title: "早退次数",
              dataIndex: "earlyCount",
              id: 15,
            },
            {
              title: "早退时长(分钟)",
              dataIndex: "earlytime",
              id: 16,
            },
            {
              title: "旷工天数",
              dataIndex: "dayAbsence",
              id: 17,
            },
          ],
        },
        {
          title: "假勤情况",
          children: [
            {
              title: "补卡次数",
              dataIndex: "addCount",
              id: 19,
            },
            {
              title: "外出",
              dataIndex: "goout",
              id: 21,
            },
            {
              title: "出差",
              dataIndex: "dayBusiness",
              id: 22,
            },
            {
              title: "年假",
              dataIndex: "dayRestYear",
              id: 23,
            },
            {
              title: "事假",
              dataIndex: "dayRestMatter",
              id: 24,
            },
            {
              title: "病假",
              dataIndex: "dayRestSick",
              id: 25,
            },
            {
              title: "调休假",
              dataIndex: "dayRestExchange",
              id: 26,
            },
            {
              title: "婚假",
              dataIndex: "dayRestMarry",
              id: 27,
            },
            {
              title: "产休假",
              dataIndex: "dayRestMaternity",
              id: 28,
            },
            {
              title: "其他",
              dataIndex: "dayRestOther",
              id: 29,
            },
          ],
        },
        {
          title: "加班统计",
          children: [
            {
              title: "加班时长(小时)",
              dataIndex: "overtimeDuration",
              id: 31,
            },
            {
              title: "工作日加班时长(小时)",
              dataIndex: "oovertimeDuration",
              id: 32,
            },
            {
              title: "休息日加班时长(小时)",
              dataIndex: "rovertimeDuration",
              id: 33,
            },
            {
              title: "节假日加班时长(小时)",
              dataIndex: "hovertimeDuration",
              id: 34,
            },
          ],
        },
      ], //导出内容参数
    };
  },
  components: { AddClasses, Rule },
  created() {
    // 获取当前日期并搜索
    this.getDate();

    // 获取节假日列表
    this.getHoliday();

    // 获取部门列表
    this.getDepartment();

    var arr = localStorage.getItem("personalreport");
    if (arr) {
      this.tableColumnList = JSON.parse(arr);
    } else {
      for (let index = 1; index < 35; index++) {
        this.tableColumnList.push(index);
      }
    }
  },
  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;
      }
    },
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 获取节假日列表
    getHoliday() {
      http
        .get("/bmsHoliday/list", {
          pageNum: 1,
          pageSize: 9999,
        })
        .then((res) => {
          if (res.data.list.length > 0) {
            var arr = this.getAllDates(res.data.list);
            this.holidayList = arr;
          }
        });
    },

    // 处理节假日日期
    getAllDates(ranges) {
      // 将日期字符串解析为 Date 对象
      const parseDate = (dateString) => new Date(dateString);

      // 生成从开始日期到结束日期的所有日期
      const getDatesInRange = (startDate, endDate) => {
        let dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
      };

      // 存储所有日期的数组
      let allDates = [];

      ranges.forEach((range) => {
        let startDate = parseDate(range.dayBegin);
        let endDate = parseDate(range.dayEnd);
        let datesInRange = getDatesInRange(startDate, endDate);
        allDates = allDates.concat(datesInRange);
      });

      // 格式化日期为 YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，需要加1，并保证两位数
        const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并保证两位数
        return `${year}-${month}-${day}`;
      };

      return allDates.map(formatDate);
    },

    // 获取访客列表
    getVisitor() {
      http
        .get(
          "/bmsAttendanceRecord/search",
          Object.assign(this.searchValueObjcet, {
            pageSize: 10,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            res.data.list.map((item) => {
              if (item.arrange > 0) {
                // 工作日
                item.work = item.secOvertime;
              } else if (item.arrange == -1 || item.arrange == -2) {
                // 休息日
                item.rest = item.secOvertime;
              } else if (item.arrange == -3) {
                // 节假日
                item.holiday = item.secOvertime;
              } else if (item.arrange == -4) {
                // 自由上下班
                item.work = item.secOvertime;
              } else {
                item.work = item.secOvertime;
              }
              if (item.bmsApplyList) {
                item.bmsApplyListArr = this.hadleAttendance(
                  item.bmsApplyList,
                  1
                );
              }
            });
            this.tableData = res.data.list;
            this.tableloading = false;
          }
        });
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 设置报表
    setReport() {
      this.setReportDialog = true;
      setTimeout(() => {
        this.$refs.tree.setCheckedKeys(this.tableColumnList);
      }, 200);
    },
    // 计算标准工作时长
    calculateHours(value) {
      if (value) {
        if (value.bmsAttendanceArrangeGetVO) {
          var arr = value.bmsAttendanceArrangeGetVO.arrangeDataList;
          if (arr) {
            var hours = 0;
            arr.map((item) => {
              var time = 0;
              // 上下班时间
              if (item.clockData) {
                var end = 0;
                if (
                  item.clockData.endDaySecond <= item.clockData.beginDaySecond
                ) {
                  end = item.clockData.endDaySecond + 86400;
                } else {
                  end = item.clockData.endDaySecond;
                }
                time = end - item.clockData.beginDaySecond;
              }

              if (item.clockDataList && item.clockDataList.length > 0) {
                item.clockDataList.map((citem) => {
                  var end = 0;
                  if (citem.endDaySecond <= citem.beginDaySecond) {
                    end = citem.endDaySecond + 86400;
                  } else {
                    end = citem.endDaySecond;
                  }
                  time = time - (end - citem.beginDaySecond);
                });
              }
              hours += parseInt(time);
            });

            return this.convertSecondsToHours(hours);
          } else {
            return "- -";
          }
        } else {
          if (value.arrange == -4) {
            var objcet = value.bmsAttendanceGroupGetVO;
            if (objcet) {
              if (objcet.attendanceGroupConfigData.freeWorkData.workTime) {
                return (
                  objcet.attendanceGroupConfigData.freeWorkData.workTime /
                    3600 +
                  "小时"
                );
              } else if (
                objcet.attendanceGroupConfigData.freeWorkData.workTime == 0
              ) {
                return "配置错误";
              } else {
                return "无限制";
              }
            } else {
              return "- -";
            }
          } else {
            return "- -";
          }
        }
      } else {
        return "- -";
      }
    },
    // 计算实际工组时长
    calculateActual(string) {
      const hours = string / 3600; // 1小时 = 3600秒
      return hours.toFixed(1);
    },
    // 判断时间在哪个时间段内
    findTimeRange(arr, a, type) {
      for (let i = 0; i < arr.length; i++) {
        const range = arr[i];
        if (a >= range.beginDaySecond && a <= range.endDaySecond) {
          if (type == 1) {
            // 开始时间
            return range.endDaySecond;
          } else {
            // 结束时间
            return range.beginDaySecond;
          }
        }
      }

      return a;
    },
    // 秒转小时
    convertSecondsToHours(seconds) {
      if (seconds) {
        return (seconds / 3600).toFixed(1) + "小时";
      } else {
        return "- -";
      }
    },

    // 关闭报表
    close() {
      this.setReportDialog = false;
    },

    // 确认修改报表
    switcOperation() {
      const list = this.$refs.tree.getCheckedKeys(true);
      this.tableColumnList = list;
      list.unshift(1, 2, 3);
      localStorage.setItem("personalreport", JSON.stringify(list));
      this.setReportDialog = false;

      this.tableKey = Math.random();
    },

    // 判断是否含有所属的表头
    judgingHeader(index) {
      if (this.tableColumnList.includes(index)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否含有所属的表头(一级)
    judgingHeader2(value) {
      var arr1 = this.tableColumnList;
      // 异常统计
      var istrue = this.hasIntersection(arr1, value);
      if (istrue) {
        return true;
      } else {
        return false;
      }
    },
    // 判断两个数组是否有交集
    hasIntersection(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i] === arr2[j]) {
            return true;
          }
        }
      }
      return false;
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 表格下载之前
    async startDownload() {
      let that = this;
      var arr = JSON.stringify(this.tableData);
      arr = JSON.parse(arr);
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      // 判断一个对象是否应该保留
      let shouldKeep = (obj) => {
        if (obj.id !== undefined) {
          return this.tableColumnList.includes(obj.id);
        } else if (obj.children !== undefined) {
          obj.children = obj.children.filter(shouldKeep);
          return obj.children.length > 0;
        }
      };
      var arr2 = this.tableColumn.filter(shouldKeep);
      var finalArr = [];
      const loading = this.$loading({
        lock: true,
        text: "导出表格中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var total = this.pages.total;

      if (this.tableSelection.length > 0) {
        finalArr = this.tableSelection;
      } else {
        if (that.searchValueObjcet.beginDay && that.searchValueObjcet.endDay) {
          var date1 = new Date(that.searchValueObjcet.beginDay);
          var date2 = new Date(that.searchValueObjcet.endDay);
          const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
          const diff = Math.abs(date2.getTime() - date1.getTime()); // 计算差值的绝对值
          var isTrue = diff > 30 * oneDay;
          if (isTrue || total >= 40000) {
            loading.close();
            that.$message.error("导出时间相隔不能超过一个月,且不能高于4万条");
            return;
          }
        }
        try {
          var arr = await http.get(
            "/bmsAttendanceRecord/search",
            Object.assign(that.searchValueObjcet, {
              pageSize: total,
              pageNum: 1,
            })
          );
          if (arr.code == 200) {
            finalArr = arr.data.list;
          } else {
            loading.close();
          }
        } catch (e) {
          loading.close();
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          if (v.arrange > 0) {
            // 工作日
            v.work = v.secOvertime;
          } else if (v.arrange == -1) {
            // 休息日
            v.rest = v.secOvertime;
          } else if (v.arrange == -3) {
            // 节假日
            v.holiday = v.secOvertime;
          } else if (v.arrange == -4) {
            // 自由上下班
            v.work = v.secOvertime;
          } else {
            v.work = v.secOvertime;
          }
          v.nameText = v.umsUser.name;
          if (v.umsUser.jobNumber) {
            v.jobNumbernew = "\xa0" + v.umsUser.jobNumber;
          }
          if (v.umsDepartment) {
            v.departmentName = v.umsDepartment.name;
          }
          v.dayStringName = v.dayString + "(" + that.weekDay(v.dayString) + ")";
          if (v.bmsAttendanceGroupGetVO) {
            if (!that.isEmptyObject(v.bmsAttendanceGroupGetVO)) {
              v.attendanceGroupName = v.bmsAttendanceGroupGetVO.name;
            }
          }

          if (v.bmsAttendanceArrangeGetVO && v.arrange > 0) {
            if (!that.isEmptyObject(v.bmsAttendanceArrangeGetVO)) {
              v.classestime = that.handleclasses(
                v.bmsAttendanceArrangeGetVO,
                1
              );
            }
          } else {
            v.classestime = that.handleclasses2(v);
          }
          v.standard = that.calculateHours(v);
          if (v.arrange > 0) {
            if (v.action) {
              v.start = that.replaceSymbol(
                v.action,
                true,
                v.bmsAttendanceArrangeGetVO
              );
            }
          } else if (v.arrange == -4) {
            if (v.action) {
              v.start = that.replaceSymbol2(
                v.action,
                v.bmsAttendanceGroupGetVO
              );
            }
          }
          if (v.secWork > 0) {
            v.actual = that.calculateActual(v.secWork) + "小时";
          } else {
            v.actual = v.secWork;
          }
          if (v.bmsApplyList) {
            v.fakeattendance = that.hadleAttendance(v.bmsApplyList, 2);
          }
          if (v.dayBusiness > 0) {
            v.dayBusiness = v.dayBusiness + "天";
          } else {
            v.dayBusiness = "- -";
          }
          if (v.dayRestYear > 0) {
            v.dayRestYear = v.dayRestYear + "天";
          } else {
            v.dayRestYear = "- -";
          }
          if (v.dayRestMatter > 0) {
            v.dayRestMatter = v.dayRestMatter + "天";
          } else {
            v.dayRestMatter = "- -";
          }
          if (v.dayRestSick > 0) {
            v.dayRestSick = v.dayRestSick + "天";
          } else {
            v.dayRestSick = "- -";
          }
          if (v.dayRestExchange > 0) {
            v.dayRestExchange = v.dayRestExchange + "天";
          } else {
            v.dayRestExchange = "- -";
          }
          if (v.dayRestMarry > 0) {
            v.dayRestMarry = v.dayRestMarry + "天";
          } else {
            v.dayRestMarry = "- -";
          }
          if (v.dayRestMaternity > 0) {
            v.dayRestMaternity = v.dayRestMaternity + "天";
          } else {
            v.dayRestMaternity = "- -";
          }
          if (v.dayRestOther > 0) {
            v.dayRestOther = v.dayRestOther + "天";
          } else {
            v.dayRestOther = "- -";
          }
          v.latetime = that.minuteConversion(v.secLate, 1);
          v.earlytime = that.minuteConversion(v.secEarly, 2);
          v.goout = that.convertSecondsToHours(v.secOut);
          v.overtimeDuration = that.handleOvertime(v.secOvertime);
          v.oovertimeDuration = that.handleOvertime(v.work);
          v.rovertimeDuration = that.handleOvertime(v.rest);
          v.hovertimeDuration = that.handleOvertime(v.holiday);
          for (let i = 0; i < arr2.length; i++) {
            if (v[arr2[i]] == undefined) {
              v[arr2[i]] = "";
            }
          }
          for (let key in v) {
            if (v[key] == null) v[key] = "";
          }
        });

        exprotExcel.export(arr2, finalArr, "个人考勤明细");
        that.$message.success("导出成功");
      } else {
        loading.close();
        that.$message.error("导出失败");
      }
    },
    // 判断一个对象是否应该保留
    shouldKeep(obj) {
      if (obj.id !== undefined) {
        return this.tableColumnList.includes(obj.id);
      } else if (obj.children !== undefined) {
        obj.children = obj.children.filter(shouldKeep);
        return obj.children.length > 0;
      }
    },
    // 页数改变
    chagePage(e) {
      this.searchSetof(e);
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
      this.searchSetof(1);
    },

    nodeClick(item) {
      const { id, name } = item;
      this.selectTree = name;
      if (item.no == 1) {
        this.selectTreeValue = null;
      } else {
        this.selectTreeValue = id;
      }
      this.$refs.stlectTree.blur();

      this.searchSetof(1);
    },

    // 删除访客弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择访客");
          return;
        }
        this.tipstitle = "确认删除所选的访客？";
        arr = ids;
      } else {
        this.tipstitle = "确认删除此访客？";
        arr = [ids];
      }
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = arr;
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },

    // 选择所选日期
    changeDate(e) {
      if (e) {
        var start = new Date(e[0]);
        var end = new Date(e[1]);

        var syear = start.getFullYear();
        var smonth =
          start.getMonth() + 1 <= 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1;
        var sday =
          start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();

        var eyear = end.getFullYear();
        var emonth =
          end.getMonth() + 1 <= 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1;
        var eday = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

        this.startTime = syear + "-" + smonth + "-" + sday;
        this.endTime = eyear + "-" + emonth + "-" + eday;
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDate();
          }, 500);
        });
      }
    },

    // 秒换成分钟
    minuteConversion(arr, type) {
      // 1是迟到，2是早退
      switch (type) {
        case 1:
          return Math.floor(arr / 60);
        case 2:
          return Math.ceil(arr / 60);
      }
    },

    // 转换成星期几
    weekDay(time) {
      const weekNum = new Date(time).getDay();
      let week = "";
      switch (weekNum) {
        case 0:
          week = "星期天";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
      }
      return week;
    },

    // 判断是否空数组
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    // 处理班次考勤时间
    handleclasses(object, type) {
      if (object) {
        var arr1 = [];
        var arr = object.arrangeDataList;
        if (arr) {
          arr.map((item) => {
            if (item.clockData.beginDaySecond >= 86400) {
              item.clockData.beginDaySecond =
                item.clockData.beginDaySecond - 86400;
            }
            if (item.clockData.endDaySecond >= 86400) {
              item.clockData.endDaySecond = item.clockData.endDaySecond - 86400;
            }
            arr1.push(
              this.convertSecondsToHMS(item.clockData.beginDaySecond) +
                " ~ " +
                this.convertSecondsToHMS(item.clockData.endDaySecond)
            );
          });

          if (type == 1) {
            return arr1.join(";");
          } else {
            return arr1;
          }
        } else {
          return "- -";
        }
      } else {
        return "- -";
      }
    },
    // 处理班次考勤时间
    handleclasses2(object) {
      var setting = object.bmsAttendanceGroupGetVO;
      if (setting) {
        if (object.arrange == -1) {
          return "休息";
        } else if (object.arrange == -2) {
          return "未排班";
        } else if (object.arrange == -3) {
          return "节假日";
        } else if (object.arrange == -4) {
          return "自由上下班";
        } else {
          return this.handleclasses(object.bmsAttendanceArrangeGetVO, 1);
        }
      } else {
        return "- -";
      }
    },
    // 判断是否是含有字符串
    containsTtrings(string) {
      var arr = ["休息", "节假日", "未排班", "自由上下班"];
      var isContain = arr.indexOf(string);
      if (isContain > -1) {
        return true;
      } else {
        return false;
      }
    },

    // 处理假勤申请
    hadleAttendance(arr, type) {
      if (arr) {
        var newArr = [];
        arr.map((item) => {
          var value = "";
          switch (item.type) {
            case 0:
              value =
                "加班" +
                this.calculateHourDifference(item.timeBegin, item.timeEnd) +
                "(" +
                this.handelTime(item.timeBegin) +
                "-" +
                this.handelTime(item.timeEnd) +
                ")";
              break;
            case 1:
              value = "补卡申请(" + this.handelTime(item.timeRecord) + ")";
              break;
            case 2:
              value =
                "请假" +
                this.formatHours(item.description) +
                "(" +
                this.leaveTime(item.description, 1) +
                "-" +
                this.leaveTime(item.description, 2) +
                ")";
              break;
            case 3:
              value =
                "出差" +
                this.formatHours(item.description) +
                "(" +
                this.leaveTime(item.description, 1) +
                "-" +
                this.leaveTime(item.description, 2) +
                ")";
              break;
            case 4:
              value =
                "外出" +
                this.calculateHourDifference(item.timeBegin, item.timeEnd) +
                "(" +
                this.handelTime(item.timeBegin) +
                "-" +
                this.handelTime(item.timeEnd) +
                ")";
              break;
          }
          newArr.push(value);
        });

        if (type == 1) {
          return newArr;
        } else {
          return newArr.join(",");
        }
      }
    },
    // 假勤次数
    attendanceFrequency(arr, type) {
      var num = 0;
      // type:1补卡次数，2是外出，3是出差，4是年假，5是事假，6是病假，7是调休假，8是婚嫁，9是产休假，10是其它
      if (arr) {
        var filteredArr = arr.filter((item) => item.status === 1);
        if (filteredArr.length > 0) {
          switch (type) {
            case 1:
              var filteredNew = arr.filter((item) => item.type === 1);
              num = filteredNew.length;
              break;
            case 2:
              var filteredNew = arr.filter((item) => item.type === 4);
              num = filteredNew.length;
              break;
            case 3:
              var filteredNew = arr.filter((item) => item.type === 3);
              num = filteredNew.length;
              break;
            case 4:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 1
              );
              num = filteredNew.length;
              break;
            case 5:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 0
              );
              num = filteredNew.length;
              break;
            case 6:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 4
              );
              num = filteredNew.length;
              break;
            case 7:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 5
              );
              num = filteredNew.length;
              break;
            case 8:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 2
              );
              num = filteredNew.length;
              break;
            case 9:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 3
              );
              num = filteredNew.length;
              break;
            case 10:
              var filteredNew = arr.filter(
                (item) => item.type === 2 && item.typeRest === 6
              );
              num = filteredNew.length;
              break;
          }
        }

        return num;
      }
    },
    // 相隔小时差
    calculateHourDifference(dateStr1, dateStr2) {
      const date1 = new Date(dateStr1);
      const date2 = new Date(dateStr2);

      // 计算时间差，单位为毫秒
      const timeDiff = Math.abs(date2 - date1);

      // 将毫秒转换为小时
      const hourDiff = (timeDiff / (1000 * 60 * 60)).toFixed(1);

      return hourDiff + "小时";
    },
    // 处理时间
    handelTime(val) {
      if (val) {
        // 将字符串解析为Date对象
        const dateTime = new Date(val);
        const formattedDateTime = `${dateTime.getFullYear()}-${(
          "0" +
          (dateTime.getMonth() + 1)
        ).slice(-2)}-${("0" + dateTime.getDate()).slice(-2)} ${(
          "0" + dateTime.getHours()
        ).slice(-2)}:${("0" + dateTime.getMinutes()).slice(-2)}`;

        return formattedDateTime;
      }
    },

    // 上下班时间
    replaceSymbol(str, isexport, val) {
      var arr = ["- -"];
      if (str) {
        var newArr = JSON.parse(str);
        if (val) {
          var arr2 = newArr.reduce((accumulator, currentValue) => {
            return [
              ...accumulator,
              currentValue.beginDaySecond,
              currentValue.endDaySecond,
            ];
          }, []);
          var arrageList = val.arrangeDataList;
          if (newArr) {
            arr = [];
            arrageList.map((item, index) => {
              var objcet = {};
              // 打上班卡时间
              var earliestWork =
                item.clockData.beginDaySecond - item.beforeAttendanceTime;
              var latestWork =
                item.clockData.beginDaySecond + item.beginNoneTime;
              var firstValueInRange = null;
              // 使用 for 循环来遍历数组
              for (var i = 0; i < arr2.length; i++) {
                var currentValue = arr2[i];

                // 检查当前值是否在 start 和 end 之间（包括 start 但不包括 end）
                if (
                  currentValue >= earliestWork &&
                  currentValue <= latestWork
                ) {
                  firstValueInRange = currentValue;
                  break; // 找到后退出循环
                }
              }
              objcet.start = firstValueInRange;

              var dutyCard = item.clockData.endDaySecond; //下班卡

              if (dutyCard < item.clockData.beginDaySecond) {
                dutyCard = dutyCard + 86400;
              }

              // 打下班卡时间
              var earliestAfter = dutyCard - item.endNoneTime;
              var latestAfter = dutyCard + item.afterAttendanceTime;

              var firstValueInRange2 = null;
              // 使用 for 循环来遍历数组
              for (var i = 0; i < arr2.length; i++) {
                var currentValue = arr2[i];

                // 检查当前值是否在 start 和 end 之间（包括 start 但不包括 end）
                if (
                  currentValue >= earliestAfter &&
                  currentValue <= latestAfter
                ) {
                  firstValueInRange2 = currentValue;
                  break; // 找到后退出循环
                }
              }
              objcet.end = firstValueInRange2;

              // 再判断上下班需不需要打卡
              if (item.beginTimeChoose) {
                if (!objcet.start) {
                  // 如果没有符合的卡
                  objcet.start = "未打卡";
                } else {
                  if (objcet.start >= 86400) {
                    objcet.start = objcet.start - 86400;
                  }
                  objcet.start = this.convertSecondsToHMS(objcet.start);
                }
              } else {
                objcet.start = "无需打卡";
              }

              // 再判断下班需不需要打卡
              if (item.endTimeChoose) {
                if (!objcet.end) {
                  // 如果没有符合的卡
                  objcet.end = "未打卡";
                } else {
                  if (objcet.end >= 86400) {
                    objcet.end = objcet.end - 86400;
                  }
                  objcet.end = this.convertSecondsToHMS(objcet.end);
                }
              } else {
                objcet.end = "无需打卡";
              }
              arr.push(objcet.start + "~" + objcet.end);
            });

            if (arr.length > 0) {
              if (isexport) {
                return arr.join(" ");
              } else {
                return arr;
              }
            } else {
              return arr;
            }
          } else {
            return arr;
          }
        } else {
          return arr;
        }
      } else {
        return arr;
      }
    },
    // 上下班时间
    // replaceSymbol(str, isexport, val) {
    //   var arr = [];
    //   if (str) {
    //     var newArr = JSON.parse(str);
    //     if (val) {
    //       var arrageList = val.arrangeDataList;
    //       newArr.map((item, index) => {
    //         var string = "";
    //         var end = "";
    //         if (arrageList[index].beginTimeChoose) {
    //           if (item.beginDaySecond) {
    //             var sting = this.convertSecondsToHMS(item.beginDaySecond);
    //             string = sting;
    //           } else {
    //             string = "未打卡";
    //           }
    //         } else {
    //           string = "无需打卡";
    //         }

    //         if (arrageList[index].endTimeChoose) {
    //           if (item.endDaySecond) {
    //             var sting = this.convertSecondsToHMS(item.endDaySecond);
    //             end = sting;
    //           } else {
    //             end = "未打卡";
    //           }
    //         } else {
    //           end = "无需打卡";
    //         }

    //         arr.push(string + " ~ " + end);
    //       });

    //       if (arr.length > 0) {
    //         if (isexport) {
    //           return arr.join(" ");
    //         } else {
    //           return arr;
    //         }
    //       } else {
    //         return "- -";
    //       }
    //     } else {
    //       return "- -";
    //     }
    //   } else {
    //     return "- -";
    //   }
    // },

    // 自由上下班时间
    replaceSymbol2(str, val) {
      if (str) {
        var newArr = JSON.parse(str);
        newArr = newArr[0];
        if (val) {
          var freeWorkData = val.attendanceGroupConfigData.freeWorkData;
          var string = "";
          var end = "";
          if (newArr.beginDaySecond) {
            if (newArr.beginDaySecond < freeWorkData.beginSecond) {
              string = "未打卡";
            } else {
              var sting = this.convertSecondsToHMS(newArr.beginDaySecond);
              string = sting;
            }
          } else {
            string = "未打卡";
          }

          if (newArr.endDaySecond == newArr.beginDaySecond) {
            end = "未打卡";
          } else {
            if (newArr.endDaySecond) {
              if (newArr.beginDaySecond < freeWorkData.beginSecond) {
                end = "未打卡";
              } else {
                if (
                  newArr.endDaySecond - freeWorkData.endSecond >=
                  newArr.beginDaySecond
                ) {
                  var sting = this.convertSecondsToHMS(newArr.endDaySecond);
                  end = sting;
                } else {
                  end = "未打卡";
                }
              }
            } else {
              end = "未打卡";
            }
          }

          return string + "-" + end;
        } else {
          return "- -";
        }
      } else {
        return "- -";
      }
    },

    // 秒数切换
    convertSecondsToHMS(seconds) {
      if (seconds > 86400) {
        seconds = seconds - 86400;
      }
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var secs = seconds % 60;

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      secs = secs < 10 ? "0" + secs : secs;

      return hours + ":" + minutes;
    },

    // 搜索集合
    searchSetof(page) {
      var object = {
        pageNum: page,
      };

      if (this.searchValue != "") {
        object.likeString = this.searchValue;
      }

      if (this.startTime && this.endTime) {
        object.beginDay = this.startTime;
        object.endDay = this.endTime;
      }

      if (this.selectTreeValue) {
        object.departmentIdList = this.selectTreeValue;
      }

      this.searchValueObjcet = object;
      this.getVisitor();
    },

    // 清除部门
    changeDe(e) {
      if (e == "") {
        this.searchValue = "";
        this.selectTreeValue = null;
        this.selectTree = [];
        this.selectDeptid = [];
        this.searchSetof(1);
      }
    },

    // 重置
    reset() {
      this.searchValue = "";
      this.selectTreeValue = null;
      this.selectTree = [];
      this.selectDeptid = [];
      this.getDate();
    },

    // 获取当前月份
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var newmonth =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate();

      var lastDate = new Date(year, month, 0).getDate() + 1; //获取当月最后一日
      if (day == "01") {
        this.startTime = year + "-" + month + "-01";
      } else {
        this.startTime = year + "-" + newmonth + "-01";
      }

      if (day <= lastDate) {
        var newday = "";
        if (day < 10) {
          newday = "0" + day;
        } else {
          newday = day;
        }
        this.endTime = year + "-" + newmonth + "-" + newday;
      }
      this.date = [this.startTime, this.endTime];

      this.searchSetof(1);
    },
    itemError(item) {
      item.headUrl = require("@/assets/head.png");
    },
    // 处理时间
    leaveTime(reason, type) {
      // 使用正则表达式匹配括号内的内容以及0.5
      // 注意：这里假设括号内只包含日期和“下午”这样的简单模式
      const regex = /\(([^)]+)\)(.*)/;
      if (reason) {
        var match = reason.match(regex);

        if (match) {
          // 括号内的内容是match[1]，0.5是match[3]（因为捕获组从1开始编号）
          var insideParens = match[1];

          if (insideParens) {
            var arr = insideParens.split(",");
            if (type == 1) {
              return arr[0];
            } else {
              return arr[1];
            }
          } else {
            return "- -";
          }
        } else {
          // 如果没有匹配到，返回一个空对象或抛出错误
          return "- -"; // 或者你可以选择抛出错误
        }
      }
    },
    splitString(str) {
      // 初始化结果数组
      let result = [];

      // 将前两个字符单独拆分
      for (let i = 0; i < 2 && i < str.length; i++) {
        result.push(str[i]);
      }

      // 将剩下的字符作为一个整体元素加入数组
      if (str.length > 2) {
        result.push(str.slice(2));
      }

      return result;
    },
    // 相差几个小时
    formatHours(reason) {
      // 使用正则表达式匹配括号内的内容以及0.5
      // 注意：这里假设括号内只包含日期和“下午”这样的简单模式
      const regex = /\(([^)]+)\)(.*)/;
      var match = reason.match(regex);

      if (match) {
        // 括号内的内容是match[1]，0.5是match[3]（因为捕获组从1开始编号）
        var insideParens = match[1];
        var zeroFive = match[2];

        if (zeroFive) {
          return zeroFive + "天";
        } else {
          return "- -";
        }
      } else {
        // 如果没有匹配到，返回一个空对象或抛出错误
        return "- -"; // 或者你可以选择抛出错误
      }
    },
    // 判断两个时间相隔几天
    daysBetweenDates(dateString1, dateString2) {
      const date1 = new Date(dateString1 + "T00:00:00"); // 开始时间设置为当天的0点
      const date2 = new Date(dateString2 + "T23:59:59"); // 结束时间设置为当天的23:59:59
      const diffInMs = date2 - date1;
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // 转换为天数并向上取整
      return diffInDays;
    },

    // 处理加班时长
    handleOvertime(val) {
      if (val) {
        return (val / 3600).toFixed(1);
      }
    },
    // 查看加班时长
    checkOverwork(val) {
      if (val) {
        var arr = JSON.parse(val);
        if (arr.beginDaySecond) {
          if (arr.beginDaySecond >= 86400) {
            arr.beginDaySecond = arr.beginDaySecond - 86400;
          }
          arr.beginDaySecond = this.convertSecondsToHMS(arr.beginDaySecond);
        } else {
          arr.beginDaySecond = "";
        }

        if (arr.endDaySecond) {
          if (arr.endDaySecond >= 86400) {
            arr.endDaySecond = arr.endDaySecond - 86400;
          }
          arr.endDaySecond = this.convertSecondsToHMS(arr.endDaySecond);
        } else {
          arr.endDaySecond = "";
        }

        return "最早:" + arr.beginDaySecond + "," + "最晚:" + arr.endDaySecond;
      }
    },

    // 查看班次
    checkClasses(id) {
      this.key = Math.random();
      this.currentClassid = id;
      setTimeout(() => {
        this.dialogFormVisible = true;
      }, 500);
    },

    // 取消班次查看
    cancelDiglog() {
      this.currentClassid = null;
      this.dialogFormVisible = false;
    },

    // 查看考勤规则
    checkRule(id) {
      this.ruleKey = Math.random();
      this.currentAttendance = id;
      setTimeout(() => {
        this.dialogRules = true;
      }, 500);
    },
    // 取消考勤规则
    cancelRuleDiglog() {
      this.currentAttendance = null;
      this.dialogRules = false;
    },

    // 假勤详情
    // checkAttendance(id, arrage, currentDay) {
    //   var that = this;
    //   this.fakeKey = Math.random();
    //   http
    //     .get("/bmsApply/get", {
    //       id: id,
    //     })
    //     .then((res2) => {
    //       if (res2.code == 200) {
    //         if (res2.data.umsUser) {
    //           that.title = res2.data.umsUser.name + "的申请";
    //         }
    //         if (res2.data.type == 2 || res2.data.type == 3) {
    //           http
    //             .get("/bmsAttendanceArrange/get/config", {
    //               id: arrage,
    //             })
    //             .then((res) => {
    //               if (res.code == 200) {
    //                 var arrangeDataList = res.data;
    //                 var first = arrangeDataList[0];
    //                 var end = arrangeDataList[arrangeDataList.length - 1];
    //                 // 最开始时间
    //                 var firstDate = first.clockData.beginDaySecond;
    //                 // 最后的时间
    //                 var endDate = end.clockData.endDaySecond;

    //                 console.log(firstDate);
    //                 console.log(endDate);
    //                 console.log(currentDay);
    //                 var result = that.calculateStartTimeAndEndTime(
    //                   firstDate,
    //                   endDate,
    //                   currentDay
    //                 );

    //                 console.log(result);
    //               }
    //             });
    //         } else {
    //           that.attendanceObject = res2.data;
    //           that.attendanceDialog = true;
    //         }
    //       }
    //     });
    // },
    checkAttendance(id) {
      this.fakeKey = Math.random();
      http
        .get("/bmsApply/get", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.umsUser) {
              this.title = res.data.umsUser.name + "的申请";
            }
            this.attendanceObject = res.data;
            this.attendanceDialog = true;
          }
        });
    },

    // 转成开始结束日期
    calculateStartTimeAndEndTime(secondsA, secondsB, currentDateString) {
      // 将日期字符串转换为Date对象
      const currentDate = new Date(currentDateString + "T00:00:00Z"); // 使用UTC时间

      // 将秒数转换为毫秒
      const msA = parseInt(secondsA, 10) * 1000;
      const msB = parseInt(secondsB, 10) * 1000;

      // 计算开始时间和结束时间
      const startTime = new Date(currentDate.getTime() + msA);
      const endTime = new Date(currentDate.getTime() + msB);

      // 格式化日期时间字符串
      function formatDateTime(date) {
        // 这里使用UTC时间进行格式化，如果需要本地时间，请调整
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }

      // 返回开始时间和结束时间的字符串
      return {
        startTime: formatDateTime(startTime),
        endTime: formatDateTime(endTime),
      };
    },
    // 关闭考勤详情
    closeAttendanceDialog() {
      this.attendanceDialog = false;
      this.attendanceObject = {};
    },
    // 拆分字符串(补卡申请)
    splitStr(str, type) {
      var arr = str.split(" ");
      if (type == 1) {
        return arr[0];
      } else {
        return arr[1];
      }
    },
    // 处理事由
    handleDesc(reason) {
      if (reason) {
        var index = reason.indexOf("("); // 查找 '(' 的索引
        if (index !== -1) {
          // 如果找到了 '('，则截取 '(' 之前的字符串
          return reason.substring(0, index);
        }
        // 如果没有找到 '('，则返回原字符串（或者你可以选择抛出一个错误）
        return "- -";
      }
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}
.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  margin-left: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.visitor_information_table {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.visitor_information_title span {
  color: #5c5c5c;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.newpagination {
  height: 50px;
  margin-top: 34px;
}
.visitor_information_search:focus-within {
  border-color: #0096fa;
}
.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
.visitor_information_t_left {
  display: flex;
  align-items: center;
}
.department_search_date {
  height: 36px;
  margin-right: 10px;
}
::v-deep .select-tree .el-select {
  margin-right: 10px;
  height: 36px;
}
::v-deep .select-tree .el-input__inner {
  height: 36px;
}
::v-deep .visitor_information_t_left .select-tree .el-select__tags {
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .visitor_information_t_left .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
.timestatus {
  width: 68px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
::v-deep .el-table__fixed {
  height: 98% !important;
}
::v-deep .el-tree-node__content {
  height: 51px;
  font-size: 15px;
  color: #5c5c5c;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .visitor_information_t_left .el-input--suffix .el-input__inner {
  height: 36px !important;
}
.ellipsis {
  width: 180px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
  display: block;
}
.ellipsis2 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
::v-deep .visitor_information_bto {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #0058ff;
  border-color: #0058ff;
}
::v-deep .visitor_information_bto2 {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #e8f4fe;
}
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  width: 100%;
  padding-top: 28px;
  box-sizing: border-box;
}
::v-deep .el-tree-node__label {
  line-height: auto;
}
.leaveDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fakeAttendance {
  width: 200px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 超出容器的文本隐藏 */
  text-overflow: ellipsis;
}

/* 假勤申请 */
.fake {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 28px 0 0 40px;
}
.fake_top {
  display: flex;
  align-items: center;
}
.fake_top_title {
  color: #1a1a1a;
  font-size: 20px;
}
.fake_content {
  display: flex;
  align-items: center;
  color: #1a1a1a;
  font-size: 18px;
  margin-top: 16px;
}
.fake_content_title {
  color: rgba(26, 26, 26, 0.6);
  width: 120px;
  text-align: left;
}
.fake_process {
  margin-top: 40px;
}
.fake_process_title {
  font-size: 20px;
  color: #1a1a1a;
}
.fake_content_time {
  margin-left: 20px;
  color: #1a1a1a;
}
.success {
  width: 45px;
  height: 20px;
  background: #1ab137;
  border-radius: 4px 4px 4px 4px;
  margin-left: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 11px;
  color: white;
}
.error {
  width: 45px;
  height: 20px;
  background: red;
  border-radius: 4px 4px 4px 4px;
  margin-left: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 11px;
  color: white;
}
</style>
